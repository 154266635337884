import React, {useEffect} from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/faculty-item.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const FacultyItem = (props) => {
  const { imgUrl, facultyName, role } = props.item;
  useEffect(()=>{
    AOS.init({duration: 1000,
      easing:"ease-in-out"});
  }, [])

  return (
    <Col lg="4" md="6" sm="12" className="mb-5">
      <div className="faculty__item" data-aos="fade-up">
        <div className="faculty__img">
          <img src={imgUrl} alt="" className="w-100" />
        </div>

        <div className="faculty__item-content mt-4">
          <h4 className="section__title social__link faculty__item-info text-center">{facultyName}
          {/* <a href={linkedin} target="_blank" rel="noopener noreferrer"><i class="ri-linkedin-box-fill"></i></a> */}
          </h4>
          
          <button className=" w-100 faculty__item-btn faculty__btn-rent">
            <Link to={`/faculties/${facultyName}`}>{role}</Link>
          </button>

        </div>
      </div>
    </Col>
  );
};

export default FacultyItem;
