import nupur from "../../assets/all-images/2023/Nupur.jpg";
import onkar from "../../assets/all-images/2023/OnkarMandlik.png";
import karan_patil from "../../assets/all-images/2023/Karan.jpg";
import rishabh from "../../assets/all-images/2023/Rishabh.jpg";
import punnyai from "../../assets/all-images/2023/Punnyai.jpg";
import haezy from "../../assets/all-images/2023/Aakash.jpg";
import ajay from "../../assets/all-images/2023/Ajay.jpg";
import riya from "../../assets/all-images/2023/Riya.jpg";
import sahana from "../../assets/all-images/2023/Sahana.jpg";
import sanika from "../../assets/all-images/2023/Sanika.jpg";
import karan_revaskar from "../../assets/all-images/2023/Karan R.jpg";
import nishant from "../../assets/all-images/2023/Nishant.jpg";
import neha from "../../assets/all-images/2023/Neha.jpg";
import rutuja from "../../assets/all-images/2023/Rutuja.jpg";

const Members2023 = [
  {
    name: "NUPUR RAJESH",
    role: "PRESIDENT",
    year: "BE-Computer",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/nupur-rajesh-a920a01a0/",
    imgUrl: nupur,
  },
  {
    name: "ONKAR MANDLIK",
    role: "VICE-PRESIDENT",
    year: "TE-IT",
    fbUrl: "#",
    instUrl: "https://www.instagram.com/_onkar_49/",
    linkedinUrl: "https://www.linkedin.com/in/onkar-mandlik-74828b1b3/",
    imgUrl: onkar,
  },
  {
    name: "KARAN PATIL",
    role: "OPERATIONS HEAD",
    year: "BE-Computer",
    fbUrl: "#",
    instUrl: "https://www.instagram.com/_.karan_patil._7888/",
    linkedinUrl: "https://www.linkedin.com/in/karan-patil-3560311b0/",
    imgUrl: karan_patil,
  },

  {
		name: "PUNNYAI SURYAWANSHI",
		role: "Jt. OPERATIONS HEAD",
		year: "TE-Computer",
    fbUrl: "#",
    instUrl: "http://www.instagram.com/_punnyai_",
    linkedinUrl: "https://www.linkedin.com/in/punnyai-suryawanshi-a7b8571b7",
    imgUrl: punnyai,
	},
	
	{
		name: "RISHABH KUMAR",
		role: "HR & PR HEAD",
		year: "TE-Computer",
    fbUrl: "#",
    instUrl: "https://www.instagram.com/rishi132002/",
    linkedinUrl: "http://www.linkedin.com/in/rishabh-kumar-a018901ba",
    imgUrl: rishabh,
	},
	
  {
		name: "AAKASH RATHOD",
		role: "TECHNICAL HEAD",
		year: "TE-Computer",
    fbUrl: "#",
    instUrl: "https://www.instagram.com/haezy.shark/",
    linkedinUrl: "https://www.linkedin.com/in/aakashrathod21",
    imgUrl: haezy,
	},

  {
		name: "AJAY BICHUKALE",
		role: "Jt. TECHNICAL HEAD",
		year: "TE-Computer",
    fbUrl: "#",
    instUrl: "https://www.instagram.com/ajay-8.3/",
    linkedinUrl: "https://www.linkedin.com/in/ajay-bichukale-110ba8230",
    imgUrl: ajay,
	},


	{
		name: "RIYA NARAYAN",
		role: "GRAPHICS HEAD",
		year: "TE-Computer",
    fbUrl: "#",
    instUrl: "https://www.instagram.com/its_riyaya/",
    linkedinUrl: "https://www.linkedin.com/in/riya-narayan-68983b229/",
    imgUrl: riya,
	},

  {
		name: "SAHANA KUMBHAR",
		role: "GRAPHICS HEAD",
		year: "TE-Computer",
    fbUrl: "#",
    instUrl: "https://www.instagram.com/____.sahana/",
    linkedinUrl: "https://www.linkedin.com/in/sahana-kumbar-0386a9223/",
    imgUrl: sahana,
	},
  
	{
		name: "SANIKA SANAP",
		role: "MARKETING HEAD",
		year: "SE-IT",
    fbUrl: "#",
    instUrl: "https://www.instagram.com/sanika.14_/",
    linkedinUrl: "https://www.linkedin.com/in/sanika-sanap-00a1a8257",
    imgUrl: sanika,
	},

	{
		name: "KARAN REVASKAR",
		role: "SOCIAL MEDIA HEAD",
		year: "TE-Electrical",
    fbUrl: "#",
    instUrl: "https://www.instagram.com/karan.revaskar/?r=nametag",
    linkedinUrl: "https://www.linkedin.com/in/karan-revaskar-b87252201",
    imgUrl: karan_revaskar,
	},
  
  {
		name: "NISHANT KENI",
		role: "SOCIAL MEDIA HEAD",
		year: "TE-Mechanical",
    fbUrl: "#",
    instUrl: "https://www.instagram.com/nishant.kenii/",
    linkedinUrl: "https://www.linkedin.com/in/nishant-keni-b9896922a",
    imgUrl: nishant,
	},

  {
		name: "NEHA UPADHYE",
		role: "CONTENT HEAD",
		year: "TE-Computer",
    fbUrl: "#",
    instUrl: "https://www.instagram.com/_nehaupadhye_/",
    linkedinUrl: "https://www.linkedin.com/in/neha-upadhye-50733b222/",
    imgUrl: neha,
	},

  {
		name: "RUTUJA KESARE",
		role: "Jt. CONTENT HEAD",
		year: "TE-Computer",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "#",
    imgUrl: rutuja,
	},
];

export default Members2023;