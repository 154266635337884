import React, { useEffect } from "react";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import img41 from "../../src/assets/all-images/aboutimg.jpg";
import "../styles/about.css";
import { motion } from "framer-motion/dist/framer-motion";
import AOS from 'aos';
import 'aos/dist/aos.css';


const About = () => {
  useEffect(()=>{
    AOS.init({duration: 1000,
      easing:"ease-in-out"});
  }, [])
  return (

    <>
    <motion.div
      initial={{translateY: 100}}
      animate={{translateY: 0}}
      exit={{translateY: 100}}
      >
      <section>

        <Container data-aos="slide-right">
          <Row>
            <Col lg="12" className="heading__col mb-5 text-center">
              <h2 className="section__title">About Us</h2>
            </Col>
          </Row>
        </Container>
        <div data-aos="fade-up"><AboutSection aboutClass="aboutPage" /></div>
        <Container>
          <Row>
            
          </Row>
        </Container>
      </section>

      <section className="about__page-section" data-aos="fade-up">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={img41} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                   TRAINING AND PLACEMENT COMMITTEE
                </h2>

                <p className="section__description">
                The Training and Placement Committee (TPC) of Pillai HOC College of Engineering and Technology assists desired final year students to get suitable job after completion of their program. However, the training and grooming commences from the very first year. Spotting talent, classification, working on the strengths and weaknesses of students has been the hallmark of our endeavors. TPC conducts Training programs to enhance soft skills and overall personality of students in association with industry and academic experts. Invitation Letters/e-mails are sent to various companies, organizations for conducting campus drives. The internships are also provided to the interested students to groom them to become job ready. The Students are given occupational trainings such as communication skills, time management and stress management skills to be able to adapt to different work cultures and environments. The teachers also prepare the company profile to assist students to understand the work culture and job pre-requisites.
                </p>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
      </motion.div>
</>
  );
};

export default About;
