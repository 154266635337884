import atharva from "../../assets/all-images/2021/Atharva Gupte.jpg";
import nupur from "../../assets/all-images/2021/Nupur Rajesh.jpg";
import shivani from "../../assets/all-images/2021/Shivani Gotavade.jpg";
import rishabh from "../../assets/all-images/2022/Rishabh Kumar.jpg";
import hindavi from "../../assets/all-images/2022/Hindavi Mhatre.jpg";
import shreyas from "../../assets/all-images/2022/Shreyas Manchekar.jpg";
import nikhil from "../../assets/all-images/2022/Nikhil.jpg";
import pranali from "../../assets/all-images/2022/Pranali Patil.jpg";
import karan from "../../assets/all-images/2022/Karan Patil.jpg";
import tushar from "../../assets/all-images/2022/Tushar.jpg";


const Members2022 = [
  {
    name: "ATHARVA GUPTE",
    role: "PRESIDENT",
    year: "BE-Automobile",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "#",
    imgUrl: atharva,
  },
  {
    name: "NUPUR RAJESH",
    role: "VICE-PRESIDENT",
    year: "TE-Computer",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "#",
    imgUrl: nupur,
  },
  {
    name: "SHIVANI GOTAVADE",
    role: "OPERATIONS HEAD",
    year: "BE-Computer",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "#",
    imgUrl: shivani,
  },

   {
	 name: "RISHABH KUMAR",
	 role: "HR & PR HEAD",
	 year: "SE-Computer",
     fbUrl: "#",
     instUrl: "https://www.instagram.com/rishi132002/",
    linkedinUrl: "http://www.linkedin.com/in/rishabh-kumar-a018901ba",
     imgUrl: rishabh,
   },
	
   {
	 name: "HINDAVI MHATARE",
	 role: "GRAPHICS HEAD",
	 year: "TE-IT",
     fbUrl: "#",
     instUrl: "https://www.instagram.com/hindavi-mhatre/",
     linkedinUrl: "https://www.linkedin.com/hindavi_2018",
     imgUrl: hindavi,
	},
	{
	 name: "SHREYAS MANCHEKAR",
	 role: "TECHNICAL HEAD",
	 year: "TE-Computer",
     fbUrl: "#",
     instUrl: "https://www.instagram.com/ig_shreyas.m/",
     linkedinUrl: "https://www.linkedin.com/shreyas-manchekar-823b58219",
     imgUrl: shreyas,
	},
    {
	 name: "NIKHIL BHOSALE",
	 role: "Jt. TECHNICAL HEAD",
	 year: "TE-Computer",
     fbUrl: "#",
     instUrl: "https://www.instagram.com/bhosale_.nikhil/",
     linkedinUrl: "https://www.linkedin.com/in/nikhil-bhosale-a3aa31221",
     imgUrl: nikhil,
	},
	{
	 name: "PRANALI PATIL",
	 role: "MARKETING HEAD",
	 year: "TE-Computer",
     fbUrl: "#",
     instUrl: "https://instagram.com/_.prxnxli._/",
     linkedinUrl: "http://www.linkedin.com/in/pranali-patil-879349243",
     imgUrl: pranali,
	},
	{
	 name: "KARAN PATIL",
	 role: "COVERAGE & SOCIAL MEDIA HEAD",
	 year: "TE-COMPUTER",
     fbUrl: "#",
     instUrl: "https://www.instagram.com/_.karan_patil._7888/",
     linkedinUrl: "https://www.linkedin.com/in/karan-patil-3560311b0/",
     imgUrl: karan,
	},
    {
	 name: "TUSHAR JADHAV",
	 role: "Jt. COVERAGE & SOCIAL MEDIA HEAD",
	 year: "TE-COMPUTER",
     fbUrl: "#",
     instUrl: "https://www.instagram.com/_jadhav_tushar/",
     linkedinUrl: "https://www.linkedin.com/in/tushar-jadhav-39391a171",
     imgUrl: tushar,
	},
  
];

export default Members2022;