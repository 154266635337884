import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import PlacedStudentCard from "../components/UI/PlacedStudentCard";
import Placed2023 from "../assets/data/placement/Placed2023";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../styles/placed-listing.css";

const Placedlisting2023 = () => {
  useEffect(()=>{
    AOS.init({duration: 1000,
      easing:"ease-in-out"});
  }, [])
  return (
      <section>

        <Container>
          <Row>
            <Col lg="12" className="heading__col mb-5 text-center">
              <h2 className="section__title">Placed Students 2022-2023 Batch</h2>
            </Col>
            {Placed2023.map((item) => (
              <PlacedStudentCard item={item} key={item.id} />
            ))}
          </Row>
        </Container>

      </section>
  );
};

export default Placedlisting2023;
