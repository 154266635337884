import img1 from "../../all-images/placements2022/Allerin/Comps.jpg";

import img2 from "../../all-images/placements2022/Capgemini/Comps-1.jpg";
import img3 from "../../all-images/placements2022/Capgemini/Comps-2.jpg";
import img4 from "../../all-images/placements2022/Capgemini/EXTC.jpg";
import img5 from "../../all-images/placements2022/Capgemini/IT.jpg";

import img6 from "../../all-images/placements2022/Coditas/Comps.jpg";
import img7 from "../../all-images/placements2022/GEP/Comps.jpg";
import img8 from "../../all-images/placements2022//J M Mhatre/gem.jpg";

import img9 from "../../all-images/placements2022/Jaro Education/Comps.jpg";
import img10 from "../../all-images/placements2022/Jaro Education/Mech.jpg";

import img11 from "../../all-images/placements2022/Lexis Nexis Risk Solutions/Comps.jpg"
import img12 from "../../all-images/placements2022/LTI/Comps.jpg";

import img13 from "../../all-images/placements2022/NSDL/Nsdl comp.jpg";
import img14 from "../../all-images/placements2022/NSDL/Nsdl IT.jpg";

import img15 from "../../all-images/placements2022/NSEIT/Comps.jpg";

import img16 from "../../all-images/placements2022/NTT/NTT comp.jpg";
import img17 from "../../all-images/placements2022/NTT/NTT IT hoc.jpg";

import img18 from "../../all-images/placements2022/Nucsoft/Comps.jpg";
import img19 from "../../all-images/placements2022/Nucsoft/EXTC.jpg";

import img20 from "../../all-images/placements2022/Reliance Jio/1.jpg";
import img21 from "../../all-images/placements2022/Reliance Jio/2.jpg";
import img22 from "../../all-images/placements2022/Reliance Jio/3.jpg";

import img23 from "../../all-images/placements2022/S P Concare/1.jpg";
import img24 from "../../all-images/placements2022/S P Concare/2.jpg";

import img25 from "../../all-images/placements2022/Ugam Solutions/Comps-1.jpg";
import img26 from "../../all-images/placements2022/Ugam Solutions/Comps-2.jpg";
import img27 from "../../all-images/placements2022/Ugam Solutions/IT.jpg";

import img28 from "../../all-images/placements2022/WonderBiz Technologies/Comps.jpg";



const Placed2022 = [
  {
    imgUrl: img1,
    company: "Allerin",
  },
  {
    imgUrl: img2,
    company: "Capgemini",
  },
  {
    imgUrl: img3,
    company: "Capgemini",
  },
  {
    imgUrl: img4,
    company: "Capgemini",
  },
  {
    imgUrl: img5,
    company: "Capgemini",
  },
  {
    imgUrl: img6,
    company: "Coditas",
  },
  {
    imgUrl: img7,
    company: "GEP",
  },
  {
    imgUrl: img8,
    company: "J M Mhatre",
  },
  {
    imgUrl: img9,
    company: "Jaro Education",
  },
  {
    imgUrl: img10,
    company: "Jaro Education",
  },
  {
    imgUrl: img11,
    company: "Lexis Nexis Risk Solutions",
  },
  {
    imgUrl: img12,
    company: "LTI",
  },
  {
    imgUrl: img13,
    company: "NSDL",
  },
  {
    imgUrl: img14,
    company: "NSDL",
  },
  {
    imgUrl: img15,
    company: "NSEIT",
  },
  {
    imgUrl: img16,
    company: "NTT",
  },
  {
    imgUrl: img17,
    company: "NTT",
  },
  {
    imgUrl: img18,
    company: "Nucsoft",
  },
  {
    imgUrl: img19,
    company: "Nucsoft",
  },
  {
    imgUrl: img20,
    company: "Reliance Jio",
  },
  {
    imgUrl: img21,
    company: "Reliance Jio",
  },
  {
    imgUrl: img22,
    company: "Reliance Jio",
  },
  {
    imgUrl: img23,
    company: "S P Concare",
  },
  {
    imgUrl: img24,
    company: "S P Concare",
  },
  {
    imgUrl: img25,
    company: "Ugam Solutions",
  },
  {
    imgUrl: img26,
    company: "Ugam Solutions",
  },
  {
    imgUrl: img27,
    company: "Ugam Solutions",
  },
  {
    imgUrl: img28,
    company: "WonderBiz Technologies",
  },

];

export default Placed2022;