import React, { useEffect } from "react";
import "../../styles/our-member.css";
import { Col } from "reactstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';

const OurMembers = (props) => {
  const { imgUrl, linkedinUrl, instUrl, name, year, role } = props.item;
  useEffect(()=>{
    AOS.init({duration: 1000,
      easing:"ease-in-out"});
  }, [])


  return (

    <Col lg="3" md="3" sm="12" xs="12" className="mb-4">
          <div className="single__member" data-aos="fade-up">
            
            <div className="single__member-img"  >
              <img src={imgUrl} alt="" className="w-100" />

              <div className="single__member-social">
                {/* <a href={fbUrl} target="_blank" rel="noopener noreferrer"><i class="ri-facebook-line"></i></a> */}
                <a href={linkedinUrl} target="_blank" rel="noopener noreferrer"><i class="ri-linkedin-line"></i></a>
                <a href={instUrl} target="_blank" rel="noopener noreferrer"><i class="ri-instagram-line"></i></a>
              </div>

            </div>

            <h6 className="section__name text-center mb-0 mt-3">{name}</h6>
            <p className="section__description text-center">
              {year}
            </p>
            <h6 className="section__role text-center mb-0 mt-3">{role}</h6>
            
            
          </div>
        </Col>

  );
};

export default OurMembers;