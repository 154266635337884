import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import PlacedStudentCard from "../components/UI/PlacedStudentCard";
import Placed2021 from "../assets/data/placement/Placed2021";
import AOS from 'aos';
import 'aos/dist/aos.css';

import "../styles/placed-listing.css";

const Placedlisting2021 = () => {
  useEffect(()=>{
    AOS.init({duration: 1000,
      easing:"ease-in-out"});
  }, [])
  return (
      <section >

        <Container>
          <Row><div data-aos="fade-right">
            <Col lg="12" className="heading__col mb-5 text-center" >
              <h2 className="section__title" >Placed Students 2020-2021 Batch</h2>
            </Col></div>
            <div data-aos="fade-left">
            {Placed2021.map((item) => (
              <PlacedStudentCard item={item} key={item.id} />
            ))}</div>
          </Row>
        </Container>

      </section>
  );
};

export default Placedlisting2021;
