import React from "react";
import Slider from "react-slick";

import "../../styles/testimonial.css";

import Prajwal from "../../assets/all-images/testimonial/Prajwal Gadge.png"
import Nishant from "../../assets/all-images/testimonial/Nishant Jadhao.png";
import Darshan from "../../assets/all-images/testimonial/Darshan Kamble.png";
import Jagruti from "../../assets/all-images/testimonial/jagruti bhagat.png";
import Pranil from "../../assets/all-images/testimonial/Pranil Mhatre Mech.png";
import Rishikesh from "../../assets/all-images/testimonial/Rishikesh Tekavade CS.png";
import Sushant from "../../assets/all-images/testimonial/Sushant bangera.png";
import Mayur from "../../assets/all-images/testimonial/Mayur Hadawale.png";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="testimonial py-4 px-3">
        <p className="section__description">
          I got placed in “Ugam Solutions”.
          I am very thankful towards our college and the placement cell for helping me to achieve this and guiding me at every stage. 
          My experience with the placement procedure was really great as I got to learn a lot. 
          The placement cell is very well versed about each step.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={Prajwal} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Prajwal Gadge</h6>
            <p className="section__description">2022 Batch, COMPUTER<br />Placed - Ugam Solutions</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          TPC PHCET has helped me a lot in getting the best placement. 
					Before the Campus Drives, many Aptitude and Coding sessions were taken by the College 
					Faculties which were the best sessions for me. The Training and Placement Cell explained 
					to us the whole placement process of each company before the company conducts its drive. 
					appreciate the efforts taken by them in providing us with the best opportunities and 
					would like to thank them for getting me placed.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={Nishant} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Nishant Jadhav</h6>
            <p className="section__description">2022 Batch, COMPUTER<br /> Placed - Jio</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          Placement department continuously put effort into placing new graduates in 
					reputed MNC helping in building beaming careers. thankyou TPC PHCET team for 
					providing me with such a great opportunity. Without TPC my dream of joining a big MNC 
					would have remained a dream.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={Darshan} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Darshan Kamble</h6>
            <p className="section__description">2022 Batch, ELECTRICAL<br /> Placed - TCS</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          I'm student of Pillai HOC College of Engineering and Technology pursuing my BE 
					in computer engineering. In month of September I got placed in Reliance jio platforms 
					with a package of 4 lpa. It definitely wouldn't be possible without constant support and 
					guidance from my Teachers, college, and Training & placement committee. When it comes to 
					placement 4 most important things that are taken into consideration are quantitative aptitude, 
					english vocabulary, logical reasoning , programming and technical skills.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={Jagruti} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Jagruti Bhagat</h6>
            <p className="section__description">2022 Batch, COMPUTER<br /> Placed - Jio</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          Gratitude & appreciation to TPC for giving us so many opportunities for getting placed. 
					The best part was we had so many companies/ options to choose from. From guiding which company 
					will fit an individual to getting prepared for the mains, all credits to TPC ! I would once again 
          like to thank each & every member to guide me through the process.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={Pranil} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Pranil Mhatre</h6>
            <p className="section__description">2022 Batch, MECHANICAL<br /> Placed - Jaro Education</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          I appreciate all the hard work done by TPC PHCET and I thank all the staff of TPC. Every 
					aspect of placemats was covered. There were several orientations regarding placements. I tried in a 
					lot of companies but I got rejected, but I didn't give up and kept applying, continued to work on 
					myself. In the end, my hard work paid off. I will also thank Mrs.Shrutika Khobragade for helping out.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={Rishikesh} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Rishikesh Tekavade</h6>
            <p className="section__description">2022 Batch, COMPUTER<br /> Placed - Ugam Solutions</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          TPC conducted various webinars and sessions on different companies and what should be the 
					strategy to crack them. Also, Seniors who were already placed in various MNCs gave their inputs 
					which helped in the preparation of these companies. I would like to thank all the faculty members 
					and the TPC team for giving the opportunity and guiding us throughout the placement process.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={Sushant} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Sushant Bangera</h6>
            <p className="section__description">2022 Batch, COMPUTER<br /> Placed - Jio</p>
          </div>
        </div>
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          Getting placed in Capgemini is like a dream come true for me. I would like to thank all 
					the members of the PHCET, P.S. Lokhande Sir and Vaishali Singh Ma'am for providing me with the 
					necessary guidance and resources due to which I was able to achieve this milestone. 
					Constant guidance from the whole team from building the resume till how I should prepare for the 
					interview was given through the webinar session conducted by PHCET and I really appreciate the 
					effort of the whole team.
        </p>

        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={Mayur} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Mayur Hadawale</h6>
            <p className="section__description">2022 Batch, COMPUTER<br /> Placed - Capgemini</p>
          </div>
        </div>
      </div>

      </Slider>
  );
};

export default Testimonial;
