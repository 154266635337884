import React, { useEffect } from "react";
import HeroSlider from "../components/UI/HeroSlider";
import "../styles/home1.css"

import { Container, Row, Col } from "reactstrap";
import AboutSection from "../components/UI/AboutSection";
import Testimonial from "../components/UI/Testimonial";
import TopCompaniesSection from "../components/UI/TopCompaniesSection";
import PlacedStudentsSection from "../components/UI/PlacedStudentsSection";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBridge, faDumbbell, faHandshake } from '@fortawesome/free-solid-svg-icons';
import {motion} from 'framer-motion/dist/framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
  useEffect(()=>{
    AOS.init({duration: 1000,
      easing:"ease-in-out"});
  }, [])
  return (
      <>
      <motion.div
      initial={{translateY: 100}}
      animate={{translateY: 0}}
      exit={{translateY: 0}}
      >
      {/* ============= hero section =========== */}
      <section className="p-0 hero__slider-section">
        <HeroSlider />

            <div className="NexusRow" >
        
        <div className="NexusColumn" data-aos="flip-up">
        <h1  className="tpc__icons"><FontAwesomeIcon icon={faBridge} color="green" /></h1>
            <h2>Nexus</h2>
            <p>
            Between students and the placement cell, TPC serves as a connector. We help students launch their careers and aid the cell in placement-related tasks.
            </p>
            
        </div>
        <div className="PlacementsColumn" data-aos="flip-up">
        <h1 className="tpc__icons"><FontAwesomeIcon icon={faHandshake} color="green" /></h1>
            <h2>Placements</h2>
            <p>
            The hiring procedure is facilitated by TPC. We make sure that the placement campaign goes smoothly for the company at every level. When students are on placements, we assist them and take account of their problems so that they may solely concentrate on their placements.
            </p>
            
        </div>
        <div className="TrainingColumn" data-aos="flip-up">
        <h1 className="tpc__icons"><FontAwesomeIcon icon={faDumbbell} color="green" /></h1>
            <h2>Training</h2>
            <p>
            TPC is in charge of aiding students with their recruitment efforts. At the placements, we prepare students for every conceivable challenge. We ensure that every qualified student gets the chance to work in the industries.
            </p>
        </div>
       </div>

      </section>

      {/* =========== Our Placed Students Section ============ */}
      <section className="latest_placement" data-aos="fade-up">
        <Container className="latest_placement_section_heading">
          <Row>
              <h2 className="section__title text-center">Our Latest Placements</h2>

              <PlacedStudentsSection />
          </Row>
        </Container>
      </section>

      {/* =========== about section ================ */}
      <div data-aos="fade-up"><AboutSection /></div>

      {/* =========== top companies section ============ */}
      <section data-aos="fade-up">
        <Container className="top_companies_section_heading">
          <Row>
              <h2 className="section__title text-center">Top Companies Hiring From PHCET</h2>

              <TopCompaniesSection />
          </Row>
        </Container>
      </section>

      {/* =========== testimonial section =========== */}
      <section data-aos="fade-up">
        <Container>
          <Row>
            <Col lg="12" className="mb-4 text-center">
              <h6 className="section__subtitle">Our placed students says</h6>
              <h2 className="section__title">Testimonials</h2>
            </Col>

            <Testimonial />
          </Row>
        </Container>
      </section>
      </motion.div>
</>
  );
};

export default Home;
