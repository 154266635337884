import React from 'react';
import WSPGallery from '../components/UI/WSPGallery';
import '../styles/events.css';
import { Container, Row, Col } from "reactstrap";

import img1 from "../../src/assets/all-images/events/campus-to-corporate.jpg";
import img2 from "../../src/assets/all-images/events/edureka.jpg";
import img3 from "../../src/assets/all-images/events/HR Interview & CV building competition.jpg";
import img4 from "../../src/assets/all-images/events/Job Fair Hack Overflow.jpg";
import img5 from "../../src/assets/all-images/events/Shiksharti.jpg";

function Events() {

  const galleryImages = [
    {
      img: img1
    },
    {
      img: img2
    },
    {
      img: img3
    },
    {
      img: img4
    },
    {
      img: img5
    },
  ]

  return (
    <div className="App">
      <br />
      <Container data-aos="slide-right">
          <Row>
            <Col lg="12" className="heading__col mb-5 text-center">
              <h2 className="section__title">Events</h2>
            </Col>
          </Row>
        </Container>

      <WSPGallery
        galleryImages={galleryImages}
      />

      <br />
    </div>
  );
}

export default Events;