// import all images from assets/images directory
import img01 from "../all-images/Faculty/Dr. J.W.Bakal (Principal).jpg";

import img02 from "../all-images/Faculty/Divya Nair.jpg"
import img03 from "../all-images/Faculty/Dinesh Mande(TPO Coordinator).jpg";
import img04 from "../all-images/Faculty/Vivek Meshram (AUTO) .jpg";
import img05 from "../all-images/Faculty/Shrutika Khobragade.jpg";
import img06 from "../all-images/Faculty/Supriya Shigwan(ELECT).jpg";
import img07 from "../all-images/Faculty/Priya Tambe (EXTC).jpg";
import img08 from "../all-images/Faculty/Shamna Sadanand (IT).jpg";
import img09 from "../all-images/Faculty/Sunil Singh Rajput (MECH).jpg";


const facultyData = [
  {
    id: 1,
    facultyName: "DR. J. W. Bakal",
    linkedin: "https://www.linkedin.com/company/tpc-phcet/",
    imgUrl: img01,
    role: "Principal",
    description: 
      "The Pillai HOC College of Engineering and Technology features a bustling campus with modern student labs. We are an NAAC A+ institution where the emphasis is on the learner's total growth in terms of practical, theoretical, and athletic skills. By staying in touch with reputable companies and industrial establishments, TPC plays a significant role in helping Undergraduates and Postgraduates who are graduating from the college find employment prospects. This placement cell works throughout the year to help graduates and employers connect. The number of students hired after campus interviews keeps increasing. Numerous reputable industries conduct interviews at the institute after being invited.",
  },

  {
    id: 2,
    facultyName: "Divya Nair",
    linkedin: "",
    imgUrl: img02,
    role: "Training & Placement Officer",
    description:
      "Pillai HOC College Of Engineering & Technology (PHCET) is one of the foremost engineering colleges in the region having seven departments (Computer, IT, Electrical, Electronics and Telecommunication, Mechanical, Civil and Automobile Engineering) founded and mentored by noted educationist Dr Vasudevan Pillai. In just over a decade, it has carved a niche for itself and is the preferred destination for students seeking graduation studies in engineering. The college is spread over 20 acres of green campus. The Placement cell serves to assist the student to explore the opportunities provided by a prospective employer. It helps students to plan their career by providing information and guiding them about the companies which approach the College for placement. The placement cell is instrumental in making students professionally ready for their work. The cell is closely coordinated by the Training and Placement Officer along with the faculty coordinators who are responsible for outreach to all key companies looking for fresh, bright and energetic engineering graduates. The Placement cell in colleges and universities play a major role in bridging the gap between industries, firms and students. The placement cell works in order to enhance the skills of the students through effective training and support. They’ll train the students to showcase their skills in the best way. PHCET welcomes all companies to its campus and assures them of the best support in finding the right talent with the help of the placement team. The cell also provides etiquette and personality development guidance to the students to help them put their best foot forward in the professional world.",
  },

  {
    id: 3,
    facultyName: "Dinesh Mande",
    linkedin: "",
    imgUrl: img03,
    role: "TPO Co-ordinator",
    description:
      "It can be very tough for the students mentally. That is why a university needs to have a good placement cell in place. University placements cells will help students prepare for their upcoming step into the professional world, and also help relieve a major part of the stress they have. TPC co-ordinates with various industries and organizations for campus recruitment. Each & Every student is capable of doing all the jobs and tasks assigned on the basis of their skills and abilities. To give them a bit of a push and some encouragement is all it takes to help them. TPC helps them to apply all their skills and launch their careers and aid the students in placement-related tasks. We assist students by conducting webinars, seminars, workshops, personality development programs, expert talks, etc., which helps the students to get ample opportunities to closely interact with professionals from different industries. TPC also helps to recognize the hidden talents and the capabilities of the students.",
  },

  {
    id: 4,
    facultyName: "Vivek Meshram",
    linkedin: "",
    imgUrl: img04,
    role: "Faculty Co-ordinator (Automobile Dept.)",
    description:
    "Seeing our students succeed is the only thing we always wish them. TPC is meant to enhance the employability of our students. The main objective of a placement cell is to place the maximum number of students through campus placement conducted by reputed companies. We motivate students to take internships during their lower semester to get acquainted with the recent trends in the industry and prepare them for their aptitude. We invite companies to conduct training sessions, workshops, which help to build relationships with the companies. We encourage the alumni of our college to get some connections to increase our placements. TPC arranges for personality development sessions, career development sessions and conducts Interview technique programs to facilitate students to perform better in the campus recruitment program.",
  },

  {
    id: 5,
    facultyName: "Shrutika Khobragade",
    linkedin: "",
    imgUrl: img05,
    role: "Faculty Co-ordinator (Computer Dept.)",
    description:
    "Generally, the best leashing materials, books, and lessons cannot help in holding the motivation aspect in students and they won't gain self-motivation as to put forth their most efforts. In this case both intrinsic and extrinsic motivation becomes important. Helping students in getting their Resume ready in the format shared by the placement team and then after multiple reviews the resume is approved and verified by the department. We as the training and placement cell strive to get industry opportunities for the students. We motivate and assist students to explore new career opportunities while grooming the potential candidates to perform exemplary at placements.",

  },

  {
    id: 6,
    facultyName: "Supriya Shigwan",
    linkedin: "",
    imgUrl: img06,
    role: "Faculty Co-ordinator (Electrical Dept.)",
    description:
      "Success doesn't come to you; you go to it. For you to chase that success, you need self-confidence. And for that self-confidence, the preparation should be top-notch. The placement cell comes into the picture to guide the students and helps them set a big impact as fresher in the ever-evolving corporate world. We aim at the all-around development of students by providing adequate resources and activities to facilitate the career planning process. We focus on enriching the requisite training for students, particularly in the areas of personality development and communication skills, which are the major life coping skills in this present scenario.",
  },

  {
    id: 7,
    facultyName: "Priya Tambe",
    linkedin: "",
    imgUrl: img07,
    role: "Faculty Co-ordinator (EXTC Dept.)",
    description:
      "PHCET has been continuously providing good infrastructure and other facilities to the Training and Placement Cell for the effective functioning of the cell. The Cell is aptly set up, not only to get the right and deserving career launch but to guide each of its students to get into the industry ethics more deeply and develop their interest in their desired careers. The TPC operates all around the year to arrange campus interviews by the companies, organizing aptitude, soft skills, and technical training classes at regular intervals to prepare the students to counter the interviews of any difficulty with sheer confidence.",
  },

  {
    id: 8,
    facultyName: "Shamna Sadanand",
    linkedin: "",
    imgUrl: img08,
    role: "Faculty Co-ordinator (IT Dept.)",
    description:
      "We at PHCET indulge ourselves to try and understand the work environment and ethics of the corporate industry and try to organize relevant events and programs that provide great opportunities for the students to get exposed to the real world of work. To accomplish all these tasks, we team up as the PLACEMENT CELL of PHCET and come out to the middle. We assist students by conducting webinars, seminars, workshops, personality development programs, expert talks, and much more. This helps the students to get abundant opportunities to closely interact with professionals from different industries and understand the work ethics of the industries. The Cell also arranges in-house training, projects, and internships with corporate organizations so that every student gets a comprehensive industry-oriented environment that helps them in polishing their skills before they kickstart their actual careers.",
  },

  {
    id: 9,
    facultyName: "Sunil Singh Rajput",
    linkedin: "",
    imgUrl: img09,
    role: "Faculty Co-ordinator (Mechanical Dept.)",
    description:
      "Training Placement Committee (TPC), as the name suggests, always works towards skilling up every aspiring student who wants to kickstart their professional career with a bang. We motivate all students to go for internships during the semester breaks to get acquainted with the recent trends in the industry and get a chance to experience what it takes to sustain in the industry world. We are here to prepare them for other skills like Aptitude, Group Discussion, Personal Interviews, and Personality Development. Also, we invite companies to train the students about the new trends in the ever-evolving Industries, which also helps them to build a relationship with the companies. We are in regular touch with our renowned alumni to help the students more and also to get some more leads so that the students get more and more opportunities to start their journeys and hereby increasing the overall placements of our college. TPC also makes sure to arrange sessions on career development and conduct Interview technique programs to ensure students perform better in the campus recruitment program.",
  },

  
];

export default facultyData;
