import React from "react";
import { Container, Row, Col } from "reactstrap";
import FacultyItem from "../components/UI/FacultyItem";
import facultyData from "../assets/data/facultyData";

const FacultyListing = () => {
  return (

      <section >

        <Container data-aos="slide-right">
          <Row>
            <Col lg="12" className="heading__col mb-5 text-center">
              <h2 className="section__title">About Faculty</h2>
            </Col>
          </Row>
        </Container>
      
        <Container >
          <Row>
            {facultyData.map((item) => (
              <FacultyItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
      </section>
  );
};

export default FacultyListing;
