import React, {useEffect} from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import logo from "./tpclogo.png";
import AOS from 'aos';
import 'aos/dist/aos.css';


const quickLinks = [
  {
    path: "/",
    display: "Home",
  },

  {
    path: "about",
    display: "About",
  },

  {
    path: "/contact",
    display: "Contact",
  },
];


const Footer = () => {
  useEffect(()=>{
    AOS.init({duration: 1000,
      easing:"ease-in-out"});
  }, [])
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer" data-aos="fade-up">
      <Container>
        <Row>
          <Col lg="4" md="12" sm="12">
            <div className="logo footer__logo">
                <Link to="/home" className=" d-flex align-items-center gap-2">
                  <img src={logo} alt="" className="logo" />
                </Link>
            </div>
            <p className="footer__logo-content">
              The <b>Training and Placement Cell</b> is an integral part of the 
              PHCET Institution. The Placement Cells primary objective is to 
              provide training and placements to college students.
            </p>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Quick Links</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Address</h5>
              <p className="office__info">Dist. Raigad, Via Panvel (Navi-Mumbai), Rasayani, Tal. Khalapur 410207, MH, India</p>
              <p className="office__info"><a target="_blank" rel="noopener noreferrer" href="tel: 02192-669000" className="services_links">Contact: 02192-669000</a></p>
              <p className="office__info"><a target="_blank" rel="noopener noreferrer" href="mailto: tpcphcet@mes.ac.in" className="services_links">Email: tpcphcet@mes.ac.in</a></p>

            </div>
          </Col>

          <Col lg="3" md="4" sm="12">

          <div className="mb-4">
          <h5 className="footer__link-title mb-4">Other Services</h5>
              <p className="office__info"><a target="_blank" rel="noopener noreferrer" href="https://linktr.ee/tpcphcet" className="services_links">LinkTree</a></p>
              <p className="office__info"><a target="_blank" rel="noopener noreferrer" href="https://phcet.ac.in" className="services_links">PHCET</a></p>

                  <div className="social__link">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/tpc.phcet/"><i class="ri-instagram-fill"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/tpc-phcet/"><i class="ri-linkedin-box-fill"></i></a>
                  </div>
          </div>

          </Col>

          
            <div className="footer__bottom">
              <p className="section__description copy_right d-flex align-items-center justify-content-center gap-1 pt-4">
                <i class="ri-copyright-line"></i>Copyright {year} | TPC-PHCET | All rights reserved.
              </p>
            </div>
        
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
