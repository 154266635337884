import React from "react";
import Slider from "react-slick";

import "../../styles/top-companies-slider.css";

import img1 from "../../assets/all-images/top-companies/1.jpg";
import img2 from "../../assets/all-images/top-companies/2.jpg";
import img3 from "../../assets/all-images/top-companies/3.jpg";
import img4 from "../../assets/all-images/top-companies/4.jpg";
import img5 from "../../assets/all-images/top-companies/5.jpg";
import img6 from "../../assets/all-images/top-companies/6.jpg";
import img7 from "../../assets/all-images/top-companies/7.jpg";
import img8 from "../../assets/all-images/top-companies/8.jpg";
import img9 from "../../assets/all-images/top-companies/9.jpg";
import img10 from "../../assets/all-images/top-companies/10.jpg";
import img11 from "../../assets/all-images/top-companies/11.jpg";
import img12 from "../../assets/all-images/top-companies/12.jpg";
import img13 from "../../assets/all-images/top-companies/13.jpg";
import img14 from "../../assets/all-images/top-companies/14.jpg";
import img15 from "../../assets/all-images/top-companies/15.jpg";
import img16 from "../../assets/all-images/top-companies/16.jpg";
import img17 from "../../assets/all-images/top-companies/17.jpg";
import img18 from "../../assets/all-images/top-companies/18.jpg";
import img19 from "../../assets/all-images/top-companies/19.jpg";
import img20 from "../../assets/all-images/top-companies/20.jpg";
import img21 from "../../assets/all-images/top-companies/21.jpg";
import img22 from "../../assets/all-images/top-companies/22.jpg";
import img23 from "../../assets/all-images/top-companies/23.jpg";
import img24 from "../../assets/all-images/top-companies/24.jpg";
import img25 from "../../assets/all-images/top-companies/25.jpg";
import img26 from "../../assets/all-images/top-companies/26.jpg";
import img27 from "../../assets/all-images/top-companies/27.jpg";
import img28 from "../../assets/all-images/top-companies/28.jpg";
import img29 from "../../assets/all-images/top-companies/29.jpg";
import img30 from "../../assets/all-images/top-companies/30.jpg";
import img31 from "../../assets/all-images/top-companies/31.jpg";

const TopCompanies = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="slider__section">
      <div className="top_companies py-4 px-3">
        <img src={img1} alt="" className="w-25 h-25 rounded-2" />
      </div>

      <div className="top_companies py-4 px-3">
        <img src={img2} alt="" className="w-25 h-25 rounded-2" />
      </div>

      <div className="top_companies py-4 px-3">
        <img src={img3} alt="" className="w-25 h-25 rounded-2" />
      </div>

      <div className="top_companies py-4 px-3">
        <img src={img4} alt="" className="w-25 h-25 rounded-2" />
      </div>

      <div className="top_companies py-4 px-3">
        <img src={img5} alt="" className="w-25 h-25 rounded-2" />
      </div>

      <div className="top_companies py-4 px-3">
        <img src={img6} alt="" className="w-25 h-25 rounded-2" />
      </div>

      <div className="top_companies py-4 px-3">
        <img src={img7} alt="" className="w-25 h-25 rounded-2" />
      </div>

      <div className="top_companies py-4 px-3">
        <img src={img8} alt="" className="w-25 h-25 rounded-2" />
      </div>

      <div className="top_companies py-4 px-3">
        <img src={img9} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img10} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img11} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img12} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img13} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img14} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img15} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img16} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img17} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img18} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img19} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img20} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img21} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img22} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img23} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img24} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img25} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img26} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img27} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img28} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img29} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img30} alt="" className="w-25 h-25 rounded-2" />
      </div>
      
      <div className="top_companies py-4 px-3">
        <img src={img31} alt="" className="w-25 h-25 rounded-2" />
      </div>
      

      </Slider>
  );
};

export default TopCompanies;
