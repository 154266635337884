import React, { useEffect } from "react";
import "../../styles/placements-card.css";
import { Col } from "reactstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';

const PlacedStudents = (props) => {
  const { imgUrl, company } = props.item;
  useEffect(()=>{
    AOS.init({duration: 1000,
      easing:"ease-in-out"});
  }, [])

  return (

    <Col lg="3" md="3" sm="12" xs="12" className="mb-4">
          <div className="single__frame" data-aos="fade-up">
            <div >
          <h6 className="section__company text-center mb-0 mt-0">{company}</h6>

            <div className="single__frame-img">
              <img src={imgUrl} alt="" className="w-100" />
            </div>            
</div>
          </div>
        </Col>

  );
};

export default PlacedStudents;