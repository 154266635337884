import React, { useEffect } from "react";

import facultyData from "../assets/data/facultyData";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import { useParams } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

const FacultyDetails = () => {
  const { slug } = useParams();

  const singleFacultyItem = facultyData.find((item) => item.facultyName === slug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [singleFacultyItem]);

  useEffect(()=>{
    AOS.init({duration: 1000,
      easing:"ease-in-out"});
  }, [])

  return (<>
    <div data-aos="slide-right">
    <Helmet title={singleFacultyItem.facultyName}>
      <section >
        <Container >
          <Row>
            <Col lg="6">
              <img src={singleFacultyItem.imgUrl} alt="" className="w-100" />
            </Col>

            <Col lg="6">
              <div className="faculty__info">
                <h2 className="section__title">{singleFacultyItem.facultyName}</h2>

                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <h6 className="rent__role fw-bold fs-4">
                    {singleFacultyItem.role}
                  </h6>
                </div>

                <p className="section__description">
                  {singleFacultyItem.description}
                </p>

              </div>
            </Col>

          </Row>
        </Container>
      </section>
    </Helmet>
    </div>
    </>
  );
};

export default FacultyDetails;
