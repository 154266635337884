import React, { useEffect } from "react";
import { Container, Row, Col} from "reactstrap";
import "../styles/committee.css";
import CommitteeTabs from "../components/UI/CommitteeTabs";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Committee = () => {
  useEffect(()=>{
    AOS.init({duration: 1000});
  }, [])
  return (
      <section>

        <Container data-aos="slide-right">
          <Row>
            <Col lg="12" className="heading__col mb-5 text-center">
              <h2 className="section__title">Our Committee Members</h2>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <CommitteeTabs />
          </Row>
        </Container>
        
      </section>
  );
};

export default Committee;
