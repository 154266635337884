import React, {useEffect} from "react";
import { useState } from "react";
import "../../styles/tabs.css";
import CommitteeCard from "./CommitteeCard";
import CommitteeCard2 from "./CommitteeCard2";
import Members2021 from "../../assets/data/2021Data";
import Members2022 from "../../assets/data/2022Data";
import Members2023 from "../../assets/data/2023Data";
import { Container, Row } from "reactstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';


function CommitteeTabs() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(()=>{
    AOS.init({duration: 1000});
  }, [])

  return (
    <div className="container" >
      <div className="bloc-tabs" data-aos="fade-up">
        <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(1)}><h6 className="section__subtitle">2022-2023 Committee</h6></button>
        <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(2)}><h6 className="section__subtitle">2021-2022 Committee</h6></button>
        <button className={toggleState === 3 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(3)}><h6 className="section__subtitle">2020-2021 Committee</h6></button>
      </div>

      <div className="content-tabs" data-aos="fade-up">
        
        {/* 2022-2023 Committee */}
        <div className={toggleState === 1 ? "content  active-content" : "content"}>
        <Container>
          <Row>
            {Members2023.map((item) => (
              <CommitteeCard item={item} key={item.id} />
            ))}
          </Row>
        </Container>
        </div>

        {/* 2021-2022 Committee */}
        <div className={toggleState === 2 ? "content  active-content" : "content"} >
        <Container>
          <Row>
            {Members2022.map((item) => (
              <CommitteeCard item={item} key={item.id} />
            ))}
          </Row>
        </Container>
        </div>

        {/* 2020-2021 Committee */}
        <div className={toggleState === 3 ? "content  active-content" : "content"}>
        <Container>
          <Row>
            {Members2021.map((item) => (
              <CommitteeCard2 item={item} key={item.id} />
            ))}
          </Row>
        </Container>
        </div>

      </div>
    </div>
  );
}

export default CommitteeTabs;
