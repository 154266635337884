import img1 from "../../all-images/placements2023/JIO/COMPS1.jpg";
import img2 from "../../all-images/placements2023/JIO/COMPS2.2.jpg";
import img3 from "../../all-images/placements2023/JIO/IT.jpg";
import img4 from "../../all-images/placements2023/hexaware/hexaware-comps.jpg";
import img5 from "../../all-images/placements2023/TSS/TSS.jpg";
import img6 from "../../all-images/placements2023/Aurionpro/aurionpro-comp.jpg";
import img7 from "../../all-images/placements2023/Aurionpro/aurionpro.jpg";
import img8 from "../../all-images/placements2023/Aurionpro/aurionpro_comp_2.jpg";
import img9 from "../../all-images/placements2023/Aurionpro/aurionpro_it_22-23.jpg";
import img10 from "../../all-images/placements2023/sanky solutions/RITE_Sanket_Ichale.jpg"
import img11 from "../../all-images/placements2023/GEP/GEP-SWAPNIL-KHANPATE.jpg";
import img12 from "../../all-images/placements2023/WTW/WTW_Shubham_Gapat.jpg";
import img13 from "../../all-images/placements2023/Rite Technologies/RITE_Sanket_Ichale.jpg";
import img14 from "../../all-images/placements2023/TCS/TCS.jpg";
import img15 from "../../all-images/placements2023/E-emphasys-comp.jpg";
import img16 from "../../all-images/placements2023/JIO/COMPS2.3.jpg";
import img17 from "../../all-images/placements2023/sanky solutions/comp_2.jpg";

import img18 from "../../all-images/placements2023/Amey1.jpg";
import img19 from "../../all-images/placements2023/aniket1.jpg";
import img20 from "../../all-images/placements2023/Dayal.jpg";
import img21 from "../../all-images/placements2023/shreyas2.jpg";


const Placed2023 = [
  {
    imgUrl: img1,
    company: "Jio",
  },
  {
    imgUrl: img2,
    company: "Jio",
  },
  {
    imgUrl: img3,
    company: "Jio",
  },
  {
    imgUrl: img4,
    company: "Hexaware",
  },
  {
    imgUrl: img5,
    company: "TSS",
  },
  {
    imgUrl: img6,
    company: "AurionPro",
  },
  {
    imgUrl: img7,
    company: "AurionPro",
  },
  {
    imgUrl: img8,
    company: "AurionPro",
  },
  {
    imgUrl: img9,
    company: "AurionPro",
  },
  {
    imgUrl: img10,
    company: "Sankey Solutions",
  },
  {
    imgUrl: img11,
    company: "GEP",
  },
  {
    imgUrl: img12,
    company: "WTW",
  },
  {
    imgUrl: img13,
    company: "Rite Technologies",
  },
  {
    imgUrl: img14,
    company: "TCS",
  },
  {
    imgUrl: img15,
    company: "E-emphasys",
  },
  {
    imgUrl: img16,
    company: "Relaince Jio",
  },
  {
    imgUrl: img17,
    company: "Sankey Solutions",
  },
  {
    imgUrl: img18,
    company: "Blue Star India Ltd Limited",
  },
  {
    imgUrl: img19,
    company: "Yotta",
  },
  {
    imgUrl: img20,
    company: "Turings Mind.ai",
  },
  {
    imgUrl: img21,
    company: "Turings Mind.ai",
  },

];

export default Placed2023;