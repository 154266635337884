import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import img31 from "../../assets/all-images/aboutimg1.jpg";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "80px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              {/* <h4 className="section__subtitle">About Us</h4> */}
              <h2 className="section__title">TRAINING AND PLACEMENT CELL</h2>
              <p className="section__description">
              The PHCET Institution's Training and Placement Cell is an essential aspect. The PHCET management is firmly committed to maintaining the Training and Placement Cell and the opportunities it brings to college students by providing a solid infrastructure and human resources. College students' training and placement is the main goal of the Placement Cells. The institution hosts a variety of events during the academic year. Students are encouraged to take charge of developing their attitudes, soft skills, and opportunities to hone their technical and analytical talents. By exposing students to the workings of the corporate world, the Training and Placement Cell aims to provide them insight into their potential future employment.
              </p>

            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={img31} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
