import img1 from "../../all-images/placements2021/placed2021.jpg";

const Placed2021 = [
  {
    imgUrl: img1,
    company: "",
  },

];

export default Placed2021;