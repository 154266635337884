import React from "react";
import "../../styles/top-companies-section.css";
import { Container, Row } from "reactstrap";

import PlacedStudents from "../../assets/data/PlacedStudentsSlider";

const PlacedStudentsSection = () => {
  return (
    <section className="top__companies">
      <Container>
        <Row>
          <PlacedStudents />
        </Row>
      </Container>
    </section>
  );
};

export default PlacedStudentsSection;