import atharva from "../../assets/all-images/2021/Atharva Gupte.jpg";
import nupur from "../../assets/all-images/2021/Nupur Rajesh.jpg";
import kunal from "../../assets/all-images/2021/Kunal Patil.jpg";
import manish from "../../assets/all-images/2021/Manish Shelke.jpg";
import tanvi from "../../assets/all-images/2021/Tanvi Raut-Technical Mentor.jpg";
import ameya from "../../assets/all-images/2021/Ameya.Salunke_Technical_Mentor.jpg";
import sushant from "../../assets/all-images/2021/Sushant Shelke.jpg";
import shivani from "../../assets/all-images/2021/Shivani Gotavade.jpg";
import kshitija from "../../assets/all-images/2021/Kshitija Chavan.jpg";

const Members2021 = [

  {
    name: "ATHARVA GUPTE",
    role: "PRESIDENT",
    year: "Mechanical",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "#",
    imgUrl: atharva,
  },
  {
    name: "NUPUR RAJESH",
    role: "VICE-PRESIDENT",
    year: "Computer",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/nupur-rajesh-a920a01a0/",
    imgUrl: nupur,
  },
  {
    name: "KUNAL PATIL",
    role: "HR & PR HEAD",
    year: "BE-Mechanical",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "#",
    imgUrl: kunal,
  },
  {
    name: "MANISH SHELKE",
    role: "GRAPHICS, COVERAGE & SOCIAL MEDIA HEAD",
    year: "BE-Civil",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "#",
    imgUrl: manish,
  },
  {
    name: "TANVI RAUT",
    role: "TECHNICAL HEAD",
    year: "BE-Computer",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "#",
    imgUrl: tanvi,
  },
  {
    name: "AMEYA SALUNKHE",
    role: "Jt. TECHNICAL HEAD",
    year: "BE-Computer",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "#",
    imgUrl: ameya,
  },
  
  {
    name: "SUSHANT SHELAKE",
    role: "CONTENT HEAD",
    year: "BE-Electrical",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "#",
    imgUrl: sushant,
  },
  
  
  {
    name: "KSHITIJA CHAVAN",
    role: "MARKETING HEAD",
    year: "BE-EXTC",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "#",
    imgUrl: kshitija,
  },

  {
    name: "SHIVANI GOTAVADE",
    role: "Jt. MARKETING HEAD",
    year: "BE-Computer",
    fbUrl: "#",
    instUrl: "#",
    linkedinUrl: "#",
    imgUrl: shivani,
  },
  
];

export default Members2021;