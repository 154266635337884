import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import "../../styles/placed-students-slider.css";

const PlacedStudents = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 250,
    swipeToSlide: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="slider__section">
      <div className="placed_students py-4 px-3">
      <Link to={`/placed2023/`}><button className="w-100 h-100 rounded-2">2023</button></Link>
      </div>

      <div className="placed_students py-4 px-3">
      <Link to={`/placed2022/`}><button className="w-100 h-100 rounded-2">2022</button></Link>
      </div>

      <div className="placed_students py-4 px-3">
      <Link to={`/placed2021/`}><button className="w-100 h-100 rounded-2">2021</button></Link>
      </div>

      </Slider>
  );
};

export default PlacedStudents;
