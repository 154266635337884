import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";

import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import Committee from "../pages/Committee";
import FacultyListing from "../pages/FacultyListing";
import FacultyDetails from "../pages/FacultyDetails";
import Events from "../pages/Events";

import PlacedListing2023 from "../pages/PlacedListing2023";
import PlacedListing2022 from "../pages/PlacedListing2022";
import PlacedListing2021 from "../pages/PlacedListing2021";
import {AnimatePresence} from 'framer-motion/dist/framer-motion';


const Routers = () => {
  const location= useLocation();
  return (
    <AnimatePresence>
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />

      <Route path="/placed2023" element={<PlacedListing2023 />} />
      <Route path="/placed2022" element={<PlacedListing2022 />} />
      <Route path="/placed2021" element={<PlacedListing2021 />} />

      {/* <Route path="/faculties" element={<FacultyListing />} />
      <Route path="/faculties" element={<FacultyListing />} /> */}

      <Route path="/about" element={<About />} /> 
      <Route path="/committee" element={<Committee />} />

      <Route path="/faculties" element={<FacultyListing />} />
      <Route path="/faculties/:slug" element={<FacultyDetails />} />

      <Route path="/events" element={<Events />} />
  
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    </AnimatePresence>
  );
};

export default Routers;
