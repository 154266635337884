import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "../styles/contact.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Contact = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out"
    });
  }, [])
  return (
    <section>
      <Container>
        <Row>

          <Container data-aos="slide-right">
            <Row>
              <Col lg="12" className="heading__col mb-5 text-center">
                <h2 className="section__title">Contact Us</h2>
              </Col>
            </Row>
          </Container>

          <Col lg="7" md="7">
            <div data-aos="flip-right">
              <iframe title="map"
                className="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3774.8611089127608!2d73.17635150661386!3d18.89324184413884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7e6af4a9b7a47%3A0x30dbd3b0d3c14416!2sPillai%20HOC%20College%20Of%20Engineering%20%26%20Technology!5e0!3m2!1sen!2sin!4v1643344055155!5m2!1sen!2sin"
                frameborder="0"
                style={{ border: "0", textAlign: "center" }}
              ></iframe>
            </div>

          </Col>

          <Col lg="5" md="5">
            <div className="contact__info contact__section" data-aos="slide-left">
              <h6 className="fw-bold">Contact Information</h6>
              <p className="section__description mb-0">
                Dist. Raigad, Via Panvel (Navi-Mumbai), Rasayani, Tal. Khalapur 410207, MH, India
              </p>
              <div className=" d-flex align-items-center gap-2" >
                <h6 className="fs-6 mb-0">Contact:</h6>
                <p className="section__description mb-0">02192-669000 | 02192-669001</p>
              </div>

              <div className=" d-flex align-items-center gap-2">
                <h6 className="mb-0 fs-6">Email:</h6>
                <p className="section__description mb-0">tpcphcet@mes.ac.in</p>
              </div>

              <h6 className="fw-bold mt-4">Follow Us</h6>

              <div className=" d-flex align-items-center gap-4 mt-3">

                <div className="social__link-icon">
                  <a href="https://www.instagram.com/tpc.phcet/"><i class="ri-instagram-fill"></i></a>
                  <a href="https://www.linkedin.com/company/tpc-phcet/"><i class="ri-linkedin-fill"></i></a>
                </div>

              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
